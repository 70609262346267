<template>
  <b-img @click="onShowImageModal(imageId)" :src="thumbnailImage" class="thumbnail cursor-pointer"></b-img>
</template>

<script>
import api from "@/services/api.js";
import { OPEN_IMAGE_MODAL } from "@/const/events.js";
export default {
  props: {
    imageId: { type: String }
  },
  data() {
    return {
      thumbnailImage: ""
    };
  },
  watch: {
    imageId() {
      this.loadImage();
    }
  },
  methods: {
    onShowImageModal(imageId) {
      this.$bus.emit(OPEN_IMAGE_MODAL, { imageId });
    },
    loadImage() {
      this.thumbnailImage = "";
      if (this.imageId) {
        api.getGoodsIcon(this.imageId).then(resp => {
          this.thumbnailImage =
            "data:application/octet-stream;base64," + new Buffer(resp.data, "binary").toString("base64");
        });
      }
    }
  },
  mounted() {
    this.loadImage();
  }
};
</script>
